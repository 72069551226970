import React from 'react';
import {
  PATH_HOME,
  PATH_AUTH,
  PATH_USER,
  PATH_LOGIN,
  PATH_GROUP,
  PATH_FLEETS,
  PATH_DRIVERS,
  PATH_SETTINGS,
  PATH_COMPANIES,
  PATH_DASHBOARD,
  PATH_TRANSACTIONS,
  PATH_FEES_AND_REFUND,
  PATH_QUERIED_TRANSACTION,
  PATH_FORGOT_PASS,
  PATH_RESET_PASS,
  PATH_LOCK_SCREEN,
  PATH_VERIFY,
  PATH_CHECK_STRIPE_CONNECT,
  PATH_CHECK_STRIPE_CONNECT_ONBOARD,
  PATH_STRIPE_CONNECT_ERROR,
  PATH_STRIPE_CONNECT_SUCESS,
  PATH_RESEND_ACTIVATION_EMAIL,
  PATH_STRIPE_RESEND_FAIL,
  PATH_STRIPE_RESEND_SUCESS,
  PATH_STRIPE_CONNECT_FAIL
} from './route.path';
import {
  Login,
  Users,
  Dashboard,
  LockScreen,
  VerifyUser,
  ResetPassword,
  Transactions,
  ForgotPassword,
  Group,
  Company,
  Drivers,
  Fleet,
  ResendActivationMail
} from 'pages';
import {
  StripeConnect,
  StripeConnectError,
  StripeConnectFail,
  StripeConnectOnboard,
  StripeConnectSuccess,
  StripeResendFail,
  StripeResendSuccess
} from 'pages/VerifyStripe';
import Error404 from 'components/errors/Error404';
import ErrorLayout from 'layouts/ErrorLayout';

const privateRoutes = [
  {
    path: PATH_TRANSACTIONS,
    name: 'transaction',
    element: <Transactions />,
    permission: 'read',
    roles: [1, 2, 3, 4, 5, 6]
  },
  {
    path: PATH_DASHBOARD,
    name: 'dashboard',
    element: <Dashboard />,
    permission: 'read',
    roles: [1, 2, 3, 4, 5, 6]
  },
  {
    path: PATH_QUERIED_TRANSACTION,
    name: 'user',
    element: null,
    permission: 'read',
    roles: [1, 2, 3, 4, 5, 6]
  },
  {
    path: PATH_FEES_AND_REFUND,
    name: 'user',
    element: null,
    permission: 'read',
    roles: [1, 2, 3, 4, 5, 6]
  },
  {
    path: PATH_GROUP,
    name: 'groups',
    element: <Group />,
    permission: 'read',
    roles: [1, 2, 3, 4, 5]
  },
  {
    path: PATH_COMPANIES,
    name: 'companies',
    element: <Company />,
    permission: 'read',
    roles: [1, 2, 3, 4, 5]
  },
  {
    path: PATH_FLEETS,
    name: 'fleets',
    element: <Fleet />,
    permission: 'read',
    roles: [1, 2, 3, 4, 5]
  },
  {
    path: PATH_DRIVERS,
    name: 'drivers',
    element: <Drivers />,
    permission: 'read',
    roles: [1, 2, 3, 4, 5, 6]
  },
  {
    path: PATH_USER,
    name: 'user',
    element: <Users />,
    permission: 'read',
    roles: [1, 2, 3, 4, 5]
  },
  {
    path: PATH_SETTINGS,
    name: 'settings',
    element: null,
    permission: 'read',
    roles: [1, 2, 3, 4, 5]
  }
];

const publicRoutes = [
  {
    path: PATH_LOGIN,
    name: 'login',
    element: <Login />,
    permission: 'read'
  },
  {
    path: PATH_FORGOT_PASS,
    name: 'forgot-pass',
    element: <ForgotPassword />,
    permission: 'read'
  },
  {
    path: PATH_RESET_PASS,
    name: 'reset-pass',
    element: <ResetPassword />,
    permission: 'read'
  },
  {
    path: PATH_LOCK_SCREEN,
    name: 'lock-screen',
    element: <LockScreen />,
    permission: 'read'
  },
  {
    path: PATH_VERIFY,
    name: 'verify-user',
    element: <VerifyUser />,
    permission: 'read'
  }
];

const funcRoutes = [
  {
    path: PATH_STRIPE_RESEND_SUCESS,
    name: 'stripe-resend-success',
    element: <StripeResendSuccess />,
    permission: 'read'
  },
  {
    path: PATH_STRIPE_CONNECT_FAIL,
    name: 'stripe-connect-fail',
    element: <StripeConnectFail />,
    permission: 'read'
  },
  {
    path: PATH_STRIPE_RESEND_FAIL,
    name: 'stripe-resend-fail',
    element: <StripeResendFail />,
    permission: 'read'
  },
  {
    path: PATH_RESEND_ACTIVATION_EMAIL,
    name: 'resend-email',
    element: <ResendActivationMail />,
    permission: 'read'
  },
  {
    path: '/auth',
    name: 'fallback',
    element: (
      <ErrorLayout isComponentWrapper>
        <Error404 />
      </ErrorLayout>
    )
  }
];

const funcRoutesWithoutLayout = [
  {
    path: PATH_CHECK_STRIPE_CONNECT,
    name: 'stripe-connect',
    element: <StripeConnect />,
    permission: 'read'
  },
  {
    path: PATH_CHECK_STRIPE_CONNECT_ONBOARD,
    name: 'stripe-connect-onboard',
    element: <StripeConnectOnboard />,
    permission: 'read'
  },
  {
    path: PATH_STRIPE_CONNECT_ERROR,
    name: 'stripe-connect-error',
    element: <StripeConnectError />,
    permission: 'read'
  },
  {
    path: PATH_STRIPE_CONNECT_SUCESS,
    name: 'stripe-connect-suecess',
    element: <StripeConnectSuccess />,
    permission: 'read'
  },
  {
    path: '/auth',
    name: 'fallback',
    element: (
      <ErrorLayout isComponentWrapper>
        <Error404 />
      </ErrorLayout>
    )
  }
];

export const routes = [
  {
    mainPath: PATH_HOME,
    childPath: privateRoutes,
    isPrivate: true
  },
  {
    mainPath: PATH_AUTH,
    childPath: publicRoutes,
    isPrivate: false
  },
  {
    mainPath: PATH_AUTH,
    childPath: funcRoutesWithoutLayout,
    withoutLayout: true,
    isFunc: true,
    isPrivate: false
  },
  {
    mainPath: PATH_AUTH,
    childPath: funcRoutes,
    isFunc: true,
    isPrivate: false
  }
];
