import React from 'react';
import { signInWithPopup, FacebookAuthProvider } from 'firebase/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { authFirebase } from 'firebase.config';
import logoFB from 'assets/img/logo-facebook.svg';

const FB = 'fb';

export default function LoginWithFB({ onLoginWithToken, loading }) {
  const facebookProvider = new FacebookAuthProvider();
  facebookProvider.addScope('business_management');
  facebookProvider.addScope('public_profile');
  facebookProvider.addScope('email');
  const signInWithFacebook = async () => {
    try {
      const responseData = await signInWithPopup(authFirebase, facebookProvider);
      onLoginWithToken(responseData.user.accessToken, FB);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <button
      onClick={signInWithFacebook}
      id="login-fb"
      disabled={loading}
      className="d-flex align-items-center"
    >
      <div className="d-flex align-items-center gap-3">
        {loading === FB ? (
          <FontAwesomeIcon icon={faSpinner} className="fs--2 fa-spin" />
        ) : (
          <img className="icon" src={logoFB} alt="Logo-facebook" />
        )}
        <span className="button-text">Sign in with Facebook</span>
      </div>
    </button>
  );
}
