import React from 'react';
import { OAuthProvider, signInWithPopup } from 'firebase/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { authFirebase } from 'firebase.config';
import logoGG from 'assets/img/logo-apple.svg';

const APPLE = 'apple';

export default function LoginWithApple({ onLoginWithToken, loading }) {
  const appleProvider = new OAuthProvider('apple.com');

  const signInWithApple = async () => {
    try {
      const responseData = await signInWithPopup(authFirebase, appleProvider);
      onLoginWithToken(responseData.user.accessToken, APPLE);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <button
      onClick={signInWithApple}
      id="login-apple"
      disabled={loading}
      className="d-flex align-items-center"
    >
      <div className="d-flex align-items-center gap-3">
        {loading === APPLE ? (
          <FontAwesomeIcon icon={faSpinner} className="fs--2 fa-spin" />
        ) : (
          <img className="icon" src={logoGG} alt="Logo-apple" />
        )}
        <span className="button-text">Sign in with Apple</span>
      </div>
    </button>
  );
}
