import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { Outlet, Navigate } from 'react-router-dom';
import Logo from 'components/common/Logo';
import Section from 'components/common/Section';
import { PATH_HOME } from 'routes/route.path';
import { useAuth } from 'hooks/useAuth';

const PublicLayout = ({ byPassAuth }) => {
  const { isLogin } = useAuth();

  if (isLogin && !byPassAuth) return <Navigate to={PATH_HOME} />;

  return (
    <Section className="py-0">
      <Row className="justify-content-center min-vh-100 py-6">
        <Col sm={12} md={8} lg={6} xxl={5}>
          <Logo to="#" />
          <Card>
            <Card.Body className="p-4 p-sm-5 p-lg-4">
              <Outlet />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Section>
  );
};

export default PublicLayout;
